import { createContext, useState } from 'react';

export const Global = createContext();

function MyProvider(props) {
    const [global, setGlobal] = useState({
        server: 'http://localhost:3002/',
    });
    return <Global.Provider value={{ global, setGlobal }}>{props.children}</Global.Provider>;
}

export default MyProvider;
