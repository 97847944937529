// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Clock_register__Pr8q1 {\n  width: 80%;\n  height: 80vh;\n  background-color: white;\n}\n\n.Clock_timeSelect__WayrW {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 88px;\n  margin: 5px 10px;\n  height: 33px;\n  background-color: #e4e6ea;\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.Clock_timeAll__UJbfW {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.Clock_activate__7h1fZ {\n  background-color: #e7f3ff;\n  color: #1a77f2;\n  border-radius: 2px;\n  border-radius: 8px;\n}", "",{"version":3,"sources":["webpack://./src/page/compoents/Clock/Clock.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;EACA,uBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,yHAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".register {\n    width: 80%;\n    height: 80vh;\n    background-color: white;\n}\n\n.timeSelect {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 88px;\n    margin: 5px 10px;\n    height: 33px;\n    background-color: #e4e6ea;\n    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.timeAll {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.activate {\n    background-color: #e7f3ff;\n    color: #1a77f2;\n    border-radius: 2px;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register": "Clock_register__Pr8q1",
	"timeSelect": "Clock_timeSelect__WayrW",
	"timeAll": "Clock_timeAll__UJbfW",
	"activate": "Clock_activate__7h1fZ"
};
export default ___CSS_LOADER_EXPORT___;
