import axiosClinet from '~/api/axiosClient';

const serviceApi = {
    getAll(params) {
        const url = '/service/getservice';
        return axiosClinet.get(url, { params });
    },
    get(id) { },
    add(data) {
        const url = '/service/addservice';
        return axiosClinet.post(url, data);
    },
    update(data) {
        const url = '/service/updateservice';
        return axiosClinet.put(url, data);
    },
    remove(id) {
        console.log(id);
        const url = '/service/removeservice';
        return axiosClinet.delete(url, { data: { id } });
    },
};

export default serviceApi;
