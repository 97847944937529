import React, { useState, useEffect } from 'react';
import style from './CalendarData.module.scss';
import { Button } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { number } from 'yup';

const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
var weekMonth = [0, 1, 2, 3, 4];
var today = new Date();
var dateOrigin = today.getDate();
var getDaysInMonth = (yearSelect, monthSelect) => {
    return new Date(yearSelect, monthSelect + 1, 0).getDate();
};

// Ngày bắt đầu vào thứ mấy
var getStartDayInMonth = (yearSelect, monthSelect) => {
    var date = new Date(yearSelect, monthSelect, 1).getDay();
    return date === 0 ? 8 : date + 1;
};

function CalendarData({ setCalender }) {
    const [monthSelect, setMonthSelect] = useState(() => today.getMonth());
    const [yearSelect, setYearSelect] = useState(() => today.getFullYear());
    const [dateSelect, setDateSelect] = useState({ date: dateOrigin, month: monthSelect, year: yearSelect });
    const [dataDay, setDataDay] = useState([]);

    useEffect(() => {
        console.log('_________________check dateSelect:  ');
        console.log(dateSelect);
        setCalender(
            `${dateSelect.year}-${dateSelect.month > 8 ? dateSelect.month + 1 : '0' + (dateSelect.month + 1)}-${
                dateSelect.date > 9 ? dateSelect.date : '0' + dateSelect.date
            }`,
        );
    }, [dateSelect.date]);

    useEffect(() => {
        var dataDayNew = [];
        for (let i = 2; i < getStartDayInMonth(yearSelect, monthSelect); i++) {
            dataDayNew.push('');
        }
        for (let i = 1; i <= getDaysInMonth(yearSelect, monthSelect); i++) {
            dataDayNew.push(i);
        }
        if (Math.ceil(dataDayNew.length / 7) === 5) {
            weekMonth = [0, 1, 2, 3, 4];
        } else {
            weekMonth = [0, 1, 2, 3, 4, 5];
        }
        setDataDay(dataDayNew);
    }, [monthSelect, yearSelect]);

    const addMonth = () => {
        if (monthSelect !== 11) {
            setMonthSelect(monthSelect + 1);
        } else {
            setMonthSelect(0);
            setYearSelect(yearSelect + 1);
        }
    };

    const subMonth = () => {
        if (monthSelect !== 0) {
            setMonthSelect(monthSelect - 1);
        } else {
            setMonthSelect(11);
            setYearSelect(yearSelect - 1);
        }
    };

    const handleClickDay = (e) => {
        console.log('??????????????????');
        const dateSelectNew = e.target.getAttribute('value');
        if (dateSelectNew) {
            setDateSelect({ date: parseInt(dateSelectNew), month: monthSelect, year: yearSelect });
        }
    };

    return (
        <div className={style.calendar}>
            <div className={style.herderCelendar}>
                <div>
                    <Button variant="" onClick={subMonth}>
                        <ArrowLeft fontSize="large" />
                    </Button>
                </div>
                <div className={style.monthTitle}>
                    Tháng {monthSelect + 1} Năm {yearSelect}
                </div>
                <div>
                    <Button variant="" onClick={addMonth}>
                        <ArrowRight fontSize="large" />
                    </Button>
                </div>
            </div>

            <div className={style.week}>
                {dayNames.map((value, index) => (
                    <div key={index} className={style.dayTitle}>
                        {value}
                    </div>
                ))}
            </div>
            {weekMonth.map((week) => (
                <div className={style.week} key={week}>
                    {dayNames.map((value, index) => {
                        let day = dataDay[week * 7 + index];
                        return (
                            <div
                                key={index}
                                className={`${style.dayTitle} ${
                                    dateSelect.date === day &&
                                    dateSelect.month === monthSelect &&
                                    dateSelect.year === yearSelect
                                        ? style.activate
                                        : ''
                                } `}
                                onClick={handleClickDay}
                                value={day}
                            >
                                {day}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export default CalendarData;
