import axiosClinet from '~/api/axiosClient';

const bookingApi = {
    getData(params) {
        const url = '/booking/getdata';
        return axiosClinet.get(url, { params });
    },
    getCustomerCalender(calender) {
        const url = '/booking/getCustomerCalender';
        return axiosClinet.get(url, {
            params: {
                calender,
            },
        });
    },
    getCustomerBookingID(customerID, calender) {
        const url = '/booking/getCustomerBookingID';
        return axiosClinet.get(url, {
            params: {
                customerID,
                calender,
            },
        });
    },
    postServiceInfo(data) {
        const url = '/booking/postServiceInfo';
        return axiosClinet.post(url, data);
    },
    getPackID(data) {
        console.log(data);
        const url = '/booking/getpackid';
        return axiosClinet.get(url, {
            params: data,
        });
    },
    check(data) {
        console.log(data);
        const url = '/booking/check';
        return axiosClinet.post(url, data);
    },
    addCustomer(data) {
        console.log(data);
        const url = '/booking/addcustomer';
        return axiosClinet.post(url, data);
    },
    addCustomerService(data) {
        console.log(data);
        const url = '/booking/addCustomerService';
        return axiosClinet.post(url, data);
    },
    addCalender(data) {
        console.log(data);
        const url = '/booking/addcalender';
        return axiosClinet.post(url, data);
    },
    addCalenderWait(data) {
        console.log(data);
        const url = '/booking/addCalenderWait';
        return axiosClinet.post(url, data);
    },
    setClock(customerID, time, dateSelect) {
        console.log('customerID:  ' + customerID);
        console.log('time:  ' + time);
        console.log('dateSelect:  ' + dateSelect);
        const url = '/booking/setclock';
        return axiosClinet.put(url, {
            customerID,
            time,
            dateSelect,
        });
    },
    confirmCustomer(customerID, dateSelect, confirm) {
        const url = '/booking/confirmcustomer';
        return axiosClinet.put(url, {
            customerID,
            dateSelect,
            confirm,
        });
    },
    editCustomer(data) {
        const url = '/booking/editCustomer';
        return axiosClinet.put(url, data);
    },
    deleteBookingCustomerId(customerID, dateSelect) {
        console.log(customerID);
        const url = '/booking/deleteBookingCustomerId';
        return axiosClinet.delete(url, { data: { customerID, dateSelect } });
    },
    deleteBookingCustomerIdServiceID(customerID, dateSelect, serviceID) {
        console.log(customerID);
        const url = '/booking/deleteBookingCustomerIdServiceID';
        return axiosClinet.delete(url, { data: { customerID, dateSelect, serviceID } });
    },
};

export default bookingApi;
