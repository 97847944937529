import Close from '@mui/icons-material/Close';
import React from 'react';
import { AddBox } from '@mui/icons-material';
import { Button } from '@mui/material';
import style from './DialogPage.module.scss';
import TodoTable from '~/page/compoents/TodoTable/TodoTable.js';

export default function DialogPage({ show, setShow, id, dataTable, setDataTable, columns, AddNew }) {
    const handleClose = () => {
        setShow(false);
        setDataTable([]);
    };

    return (
        show && (
            <div className={style.page}>
                <div className={style.close}>
                    <h3 style={{ marginTop: '8px' }}>ID: {id}</h3>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <Button
                                sx={{ marginRight: '10px' }}
                                onClick={AddNew}
                                variant="contained"
                                component="label"
                                startIcon={<AddBox />}
                            >
                                Add New
                            </Button>
                        </div>

                        <div>
                            <Button aria-label="Close" variant="contained" onClick={handleClose} startIcon={<Close />}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <TodoTable rows={dataTable} columns={columns} />
                    </div>
                </div>
            </div>
        )
    );
}
