import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
// Giao diện
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Settings from '@mui/icons-material/Settings';
import Swal from 'sweetalert2';

// import file
import doctorApi from '~/api/doctor/doctorApi.js';
import TodoForm from '~/page/compoents/TodoForm/TodoForm.js';
import ImportExcel from '~/page/compoents/ImportExcel/ImportExcel.js';
import TodoTable from '~/page/compoents/TodoTable/TodoTable.js';
import Search from '~/page/compoents/Search/Search.js';
import DialogPage from '~/page/compoents/DialogPage/DialogPage.js';
// Origin
// import data excel
const dataTitleExcel = [
    'doctorID',
    'doctorName',
    'doctorGroup',
    'doctorJob',
    'doctorWeek',
    'serviceID',
    'serviceName',
    'serviceTime',
];
//  input form
const formDataOrigin = [
    { name: 'doctorName', label: 'Tên bác sĩ:', type: 'text' },
    { name: 'doctorGroup', label: 'Chuyên ngành', type: 'text' },
    { name: 'doctorJob', label: 'Công việc', type: 'text' },
    { name: 'doctorWeek', label: 'Tuần', type: 'text' },
];
// Điều kiện form
const condition = yup.object().shape({
    doctorName: yup.string().required('Vui lòng nhập dữ liệu!'),
});

// input form DoctorID
const formDataOriginDoctorID = [
    { name: 'serviceID', label: 'Mã dịch vụ:', type: 'text' },
    { name: 'serviceName', label: 'Tên dịch vụ:', type: 'text' },
    { name: 'serviceTime', label: 'Thời gian', type: 'text' },
];
// Điều kiện
const conditionDoctorID = yup.object().shape({
    serviceID: yup.string().required('Vui lòng nhập dữ liệu!'),
});
// origin input form addNEw item Doctor
const formDataOriginAddDoctorID = [
    { name: 'serviceID', label: 'Mã dịch vụ:', type: 'text' },
    { name: 'serviceName', label: 'Tên dịch vụ:', type: 'text' },
    { name: 'serviceTime', label: 'Thời gian', type: 'text' },
];
const conditionAddDoctorID = yup.object().shape({
    serviceID: yup.string().required('Vui lòng nhập dữ liệu!'),
});

function Doctor() {
    console.log('check Loop');
    // data table 1
    const [dataTable, setDataTable] = useState([]);
    // data table 2
    const [dataTableDoctorID, setDataTableDoctorID] = useState([]);
    // form
    const [showForm, setShowForm] = useState(false);
    const [originForm, setOriginForm] = useState({
        data: formDataOrigin,
        condition: condition,
    });
    const [defaultValues, setDefaultValues] = useState();
    const [titleForm, setTitleForm] = useState('Add New');
    const [confirmForm, setConfirmForm] = useState({ confirm: doctorApi.add });
    // page
    const [doctorID, setDoctorID] = useState('');
    const [doctorInfo, setDoctorInfo] = useState('');
    const [showDialogPage, setShowDialogPage] = useState(false);
    //search
    const [dataSearch, setDataSearch] = useState();

    // call data table 1
    const getDataTable = async () => {
        const dataTableNew = await doctorApi.getAll();
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTable(dataStt);
    };

    useEffect(() => {
        getDataTable();
    }, []);

    // table 1
    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 0.5 },
        { field: 'id', headerName: 'Mã', minWidth: 100, flex: 1 },
        { field: 'doctorName', headerName: 'Tên bác sĩ', minWidth: 250, flex: 3 },
        { field: 'doctorGroup', headerName: 'Chuyên ngành', minWidth: 200, flex: 2 },
        { field: 'doctorJob', headerName: 'Công việc', minWidth: 150, flex: 1.5 },
        { field: 'doctorWeek', headerName: 'Tuần', minWidth: 150, flex: 1.5 },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <>
                    <EditIcon
                        onClick={() => updateSubmit(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />

                    <Settings
                        onClick={() => handleOpenDoctorId(params.row.id, params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removeTable(params.row)} style={{ cursor: 'pointer' }} />
                </>
            ),
        },
    ];

    // Edit time
    // Function in Table Doctor
    const updateSubmit = (data) => {
        console.log(data);
        setTitleForm('Update New');
        setDefaultValues(data);
        setOriginForm({
            data: formDataOrigin,
            condition: condition,
        });
        setConfirmForm({ confirm: updateTable });
        setShowForm(true);
    };

    const updateTable = async (data) => {
        console.log(data);
        const result = await doctorApi.update(data);
        if (result.result) {
            setShowForm(false);
            Swal.fire('Update!', 'Update success !', 'success');
            getDataTable();
        } else {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: result.message,
            });
        }
    };

    const removeTable = async (data) => {
        // console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Delete ?',
            text: data.id,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await doctorApi.remove(data.id);
            if (resultRemove) {
                Swal.fire('Deleted!', data.id, 'success');
                getDataTable();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: 'Something went wrong!',
                });
            }
        }
    };

    const handleOpenDoctorId = (id, info) => {
        setDoctorID(id);
        setDoctorInfo(info);
        getDataTableDoctorID(id);
        setShowDialogPage(true);
    };

    // call data table 2
    const getDataTableDoctorID = async (id) => {
        const dataTableNew = await doctorApi.getDoctorID(id);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTableDoctorID(dataStt);
    };

    // Table 2
    const columnsDoctorId = [
        { field: 'stt', headerName: 'STT', flex: 0.5 },
        { field: 'serviceID', headerName: 'serviceID', flex: 1 },
        { field: 'serviceName', headerName: 'serviceName', flex: 3 },
        { field: 'serviceTime', headerName: 'serviceTime', flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <EditIcon
                        onClick={() => updateSubmitDoctorId(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon
                        onClick={() => removeTableDoctorId(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                </>
            ),
        },
    ];

    // Function in Table DoctorID
    const updateSubmitDoctorId = (data) => {
        console.log(data);
        setTitleForm('Update New');
        setOriginForm({
            data: formDataOriginDoctorID,
            condition: conditionDoctorID,
        });
        setDefaultValues(data);
        setConfirmForm({ confirm: updateTableDoctorId });
        setShowForm(true);
    };

    const updateTableDoctorId = async (data) => {
        const result = await doctorApi.updateDoctorItem(data);
        if (result.result) {
            setShowForm(false);
            Swal.fire('Update!', 'Update success !', 'success');
            getDataTableDoctorID(doctorID);
        } else {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: result.message,
            });
        }
    };

    const removeTableDoctorId = async (data) => {
        // console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Delete ?',
            text: data.serviceID,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await doctorApi.removeDoctorItem(data.id);
            if (resultRemove) {
                Swal.fire('Deleted!', data.serviceID, 'success');
                getDataTableDoctorID(doctorID);
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: 'Something went wrong!',
                });
            }
        }
    };

    // add new
    const addNewSubmit = () => {
        setTitleForm('Update New');
        setOriginForm({
            data: formDataOriginAddDoctorID,
            condition: conditionAddDoctorID,
        });
        setDefaultValues({});
        setConfirmForm({ confirm: addNew });
        setShowForm(true);
    };

    const addNew = async (data) => {
        // console.log('ADD new');
        // console.log({ ...data, ...doctorInfo });
        const result = await doctorApi.add({ ...data, ...doctorInfo, doctorID });
        console.log(result);
        if (result.result) {
            setShowForm(false);
            Swal.fire('Add New!', 'Add New success !', 'success');
            getDataTableDoctorID(doctorID);
        } else {
            // setShowForm(false);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: result.message,
            });
        }
    };

    // search
    const handleSearch = (e) => {
        e.preventDefault();
        console.log(dataSearch);
    };

    return (
        <div>
            <div className="between">
                <div>
                    <form onSubmit={handleSearch}>
                        <Search setDataSearch={setDataSearch} />
                    </form>
                </div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <ImportExcel add={doctorApi.add} dataTitleExcel={dataTitleExcel} loadData={getDataTable} />
                    </div>
                </div>
            </div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultValues}
                        formDataOrigin={originForm.data}
                        title={titleForm}
                        condition={originForm.condition}
                        setDataTable={setDataTable}
                    />
                )}
            </div>
            <div>
                <TodoTable rows={dataTable} columns={columns} />
            </div>
            <DialogPage
                show={showDialogPage}
                setShow={setShowDialogPage}
                id={doctorID}
                columns={columnsDoctorId}
                dataTable={dataTableDoctorID}
                setDataTable={setDataTableDoctorID}
                AddNew={addNewSubmit}
            />
        </div>
    );
}

export default Doctor;
