import { useNavigate, NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { LogoutSharp } from '@mui/icons-material';
import styles from './Sidebar.module.scss';
import logo from '~/Layouts/components/img/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';

const menuOrigin = [
    { link: '/', content: 'Booking', rule: 'all' },
    { link: '/calendar', content: 'Lịch làm việc', rule: 'all' },
    // { link: '/customer', content: 'QL Khách hàng' },
    { link: '/doctor', content: 'QL Bác sĩ', rule: 'admin' },
    // { link: '/service', content: 'QL Dịch vụ' },
    { link: '/servicepack', content: 'QL Gói', rule: 'admin' },
    { link: '/account', content: 'QL Tài khoản', rule: 'admin' },
];

function Sidabar() {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('rule');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const menuOpen = () => {
        console.log('check');
        document.getElementById('menuOpen').style.display = 'block';
    };

    const menuClose = () => {
        const check = document.getElementById('menuOpen').style.display;
        if (check) {
            document.getElementById('menuOpen').style.display = 'none';
        }
    };

    return (
        <nav className={styles.sidebar}>
            <div className={styles.sidebar_logo}>
                <div className={styles.info_all}>
                    <div>
                        <img id={styles.logo} src={logo} alt="logo" />
                    </div>
                    <div className={styles.info_people}>
                        <div className={styles.info}>
                            <div>
                                <PersonIcon /> &ensp;
                            </div>
                            <div className={styles.info_vp}> {localStorage.getItem('user')}</div>
                        </div>
                        <div className={styles.info}>
                            <div>
                                <SettingsSuggestRoundedIcon /> &ensp;
                            </div>
                            <div className={styles.info_vp}>{localStorage.getItem('rule')}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.menuButon}>
                    <Button
                        id={styles.logout}
                        variant="contained"
                        color="error"
                        startIcon={<LogoutSharp />}
                        onClick={logout}
                    >
                        Đăng xuất
                    </Button>
                    <Button
                        className={styles.menuNav}
                        style={{ marginLeft: '10px' }}
                        startIcon={<MenuIcon />}
                        variant="contained"
                        onClick={menuOpen}
                    >
                        Menu
                    </Button>
                </div>
            </div>

            <div className={styles.menu} id="menuOpen" onClick={menuClose}>
                <ul className={styles.sidebar_menu}>
                    {menuOrigin.map((value, index) => {
                        var rule = localStorage.getItem('rule');
                        if (value.rule === 'all' || value.rule === rule)
                            return (
                                <li key={index}>
                                    <NavLink
                                        to={value.link}
                                        className={(navData) => (navData.isActive ? styles.active : 'none')}
                                    >
                                        {value.content}
                                    </NavLink>
                                </li>
                            );
                    })}
                </ul>
            </div>
        </nav>
    );
}

export default Sidabar;
