import axiosClinet from '~/api/axiosClient';

const packApi = {
    getAll(params) {
        const url = '/pack/getpack';
        return axiosClinet.get(url, { params });
    },
    getPackID(id) {
        console.log(id);
        const url = '/pack/getpackid';
        return axiosClinet.get(url, {
            params: {
                id,
            },
        });
    },
    add(data) {
        // console.log(data);
        const url = '/pack/addpack';
        return axiosClinet.post(url, data);
    },
    update(data) {
        const url = '/pack/updatepack';
        return axiosClinet.put(url, data);
    },
    updatePackItem(data) {
        console.log(data);
        const url = '/pack/updatepackitem';
        return axiosClinet.put(url, data);
    },
    remove(id) {
        console.log(id);
        const url = '/pack/removepack';
        return axiosClinet.delete(url, { data: { id } });
    },
    removePackItem(id) {
        console.log(id);
        const url = '/pack/removepackitem';
        return axiosClinet.delete(url, { data: { id } });
    },
};

export default packApi;
