import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import * as yup from 'yup';
import Swal from 'sweetalert2';

import TodoTable from '~/page/compoents/TodoTable/TodoTable.js';
import auth2Api from '~/api/auth/auth2.js';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TodoForm from '~/page/compoents/TodoForm/TodoForm.js';

//  input Account:
const accountForm = [
    { name: 'id', label: 'Tài khoản', type: 'text' },
    { name: 'pass', label: 'Mật khẩu', type: 'text' },
    { name: 'name', label: 'Tên người dùng', type: 'text' },
    { name: 'manager', label: 'Chức năng', type: 'text' },
];
// Điều kiện form pack ID
const conditionAccount = yup.object().shape({
    id: yup.string().required('Vui lòng nhập dữ liệu!'),
    name: yup.string().required('Vui lòng nhập dữ liệu!'),
    manager: yup.string()
        .required('Vui lòng nhập dữ liệu!')
        .oneOf(["admin", "saleAdmin", "sale"], 'Các giá trị có thể dùng: admin, sale, saleAdmin'),
});


function Account() {
    const [checkAdmin, setCheckAdmin] = useState(() => { return localStorage.getItem("user") === 'admin' });
    const [dataTable, setDataTable] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [originForm, setOriginForm] = useState({
        data: accountForm,
        condition: conditionAccount,
    });


    const [defaultValues, setDefaultValues] = useState();
    const [titleForm, setTitleForm] = useState('Add New');
    const [confirmForm, setConfirmForm] = useState({ confirm: auth2Api.add });

    console.log(checkAdmin);

    // call data table 1
    const getDataTable = async () => {
        // setDataTable(await packApi.getAll());
        const dataTableNew = await auth2Api.getData();
        console.log(dataTableNew);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));

        setDataTable(dataStt);
    };

    useEffect(() => {
        if (checkAdmin)
            getDataTable();
    }, [checkAdmin]);

    // table 1:
    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 1 },
        { field: 'id', headerName: 'Tài khoản', minWidth: 150, flex: 1 },
        { field: 'name', headerName: 'Tên', minWidth: 150, flex: 1 },
        { field: 'manager', headerName: 'Chức năng', minWidth: 150, flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <>
                    <EditIcon
                        onClick={() => editAccount(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => DeleteAcount(params.row.id)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                </>
            ),
        },
    ];

    const addNewAccount = () => {
        setTitleForm("Thêm tài khoản mới")
        setOriginForm({
            data: accountForm,
            condition: conditionAccount,
        });
        setDefaultValues([]);
        setConfirmForm({ confirm: addAcount });
        setShowForm(true);
    }

    const addAcount = async (data) => {
        console.log(data);
        const confirm = await auth2Api.addAcount(data);
        console.log(confirm);
        if (confirm.result) {
            Swal.fire('Add new!', 'Thêm tài khoản thành công !', 'success');
            setShowForm(false);
            getDataTable();
        } else {
            Swal.fire('Add new!', 'Thêm tài khoản không thành công !', 'error');
            getDataTable();
        }
    }

    const editAccount = (data) => {
        setTitleForm("Chỉnh sửa tài khoản:")
        setOriginForm({
            data: accountForm,
            condition: conditionAccount,
        });
        setDefaultValues(data);
        setConfirmForm({ confirm: editAcountApi });
        setShowForm(true);
    }

    const editAcountApi = async (data) => {
        console.log(data);
        const confirm = await auth2Api.editAcount(data);
        console.log(confirm);
        if (confirm.result) {
            Swal.fire('Add new!', 'Thêm tài khoản thành công !', 'success');
            setShowForm(false);
            getDataTable();
        } else {
            Swal.fire('Add new!', 'Thêm tài khoản không thành công !', 'error');
            getDataTable();
        }
    }


    const DeleteAcount = async (userName) => {
        console.log(userName);
        const confirm = await auth2Api.deleteAcount(userName);
        console.log(confirm);
        if (confirm.result) {
            Swal.fire('Delete Account!', ' Xóa tài khoản thành công !', 'success');
            getDataTable();
        } else {
            Swal.fire('Delete Account!', ' Xóa tài khoản không thành công !', 'error');
            getDataTable();
        }
    }

    return (
        !checkAdmin || (
            <div>

                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}

                        defaultValues={defaultValues}

                        formDataOrigin={originForm.data}

                        title={titleForm}

                        condition={originForm.condition}

                        setDataTable={setDataTable}
                    />
                )}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ marginLeft: '10px' }}>
                        <Button
                            onClick={addNewAccount}
                            variant="contained"
                            component="label"
                            startIcon={<AddBox />}
                        >
                            Add New
                        </Button>
                    </div>
                </div>
                <TodoTable rows={dataTable} columns={columns} />
            </div>
        )
    )

}

export default Account