// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_content__VGjpR {\n  padding-left: 242px;\n}\n\n.DefaultLayout_content_info__pMMPp {\n  min-width: 500px;\n  margin: 5px;\n  padding: 20px 30px;\n}\n\n@media only screen and (max-width: 1200px) {\n  .DefaultLayout_content__VGjpR {\n    padding-left: 5px;\n    padding-right: 5px;\n    padding-top: 100px;\n  }\n}\n@media only screen and (max-width: 800px) {\n  .DefaultLayout_content__VGjpR {\n    padding-left: 0px;\n    padding-right: 0px;\n    padding-top: 90px;\n  }\n  .DefaultLayout_content_info__pMMPp {\n    padding-top: 0px;\n    padding: 10px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Layouts/DefaultLayout/DefaultLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI;IACI,iBAAA;IACA,kBAAA;IACA,kBAAA;EACN;AACF;AAEA;EACI;IACI,iBAAA;IACA,kBAAA;IACA,iBAAA;EAAN;EAGE;IACI,gBAAA;IACA,aAAA;EADN;AACF","sourcesContent":[".content {\n    padding-left: 242px;\n}\n\n.content_info {\n    min-width: 500px;\n    margin: 5px;\n    padding: 20px 30px;\n}\n\n@media only screen and (max-width: 1200px) {\n    .content {\n        padding-left: 5px;\n        padding-right: 5px;\n        padding-top: 100px;\n    }\n}\n\n@media only screen and (max-width: 800px) {\n    .content {\n        padding-left: 0px;\n        padding-right: 0px;\n        padding-top: 90px;\n    }\n\n    .content_info {\n        padding-top: 0px;\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "DefaultLayout_content__VGjpR",
	"content_info": "DefaultLayout_content_info__pMMPp"
};
export default ___CSS_LOADER_EXPORT___;
