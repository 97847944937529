import { useEffect, useState } from 'react';
import { IconButton, Button } from '@mui/material';
import { Close, ContentPasteSearch } from '@mui/icons-material';
import TodoTable from '~/page/compoents/TodoTable/TodoTable.js';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Swal from 'sweetalert2';
import * as yup from 'yup';
// Giao diện
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Settings from '@mui/icons-material/Settings';

import SearchData from '../compoents/SearchData/SearchData';
import CalendarData from '~/components/CalendarData/CalendarData';
import bookingApi from '~/api/booking/bookingApi';
import style from './Booking.module.scss';
import Register from '../compoents/Register/Register';
import Clock from '../compoents/Clock/Clock';
import ServiceInfo from '../compoents/ServiceInfo/ServiceInfo';
import TodoForm from '../compoents/TodoForm/TodoForm.js';
import DialogPage from '~/page/compoents/DialogPage/DialogPage.js';
import AddServiceCustomer from '../compoents/AddServiceCustomer/AddServiceCustomer';

const formDataOrigin = [
    { name: 'customerName', label: 'Tên khách hàng', type: 'text' },
    { name: 'birthday', label: 'Năm sinh', type: 'text' },
    { name: 'phone', label: 'Liên hệ', type: 'text' },
    { name: 'note', label: 'Lý do khám', type: 'text' },
];

// Điều kiện
const condition = yup.object().shape({
    customerName: yup.string().required('Vui lòng nhập dữ liệu!'),
    birthday: yup.string().required('Vui lòng nhập dữ liệu!'),
    phone: yup.string().required('Vui lòng nhập dữ liệu!'),
    note: yup.string().required('Vui lòng nhập dữ liệu!'),
});

function Booking() {
    const [calender, setCalender] = useState('');
    const [data, setData] = useState([]);
    const [dataPack, setDataPack] = useState([]);
    const [dataService, setDataService] = useState([]);
    const [dataDate, setDataDate] = useState([]);
    const [dateSelect, setDateSelect] = useState('');
    const [timeSelect, setTimeSelect] = useState('');
    const [customerSelect, setCustomerSelect] = useState('');
    const [showRegister, setShowRegister] = useState(false);
    const [showClock, setShowClock] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [showServiceInfo, setShowServiceInfo] = useState(false);
    const [satusDate, setSatusDate] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [confirmForm, setConfirmForm] = useState({ confirm: bookingApi.edit });
    const [titleForm, setTitleForm] = useState('Edit customer');
    const [defaultValues, setDefaultValues] = useState();
    const [confirmRegister, setconfirmRegister] = useState(false);
    const [originForm, setOriginForm] = useState({
        data: formDataOrigin,
        condition: condition,
    });

    const [showDialogPage, setShowDialogPage] = useState(false);
    const [dataTableCustomerID, setDataTableCustomerID] = useState([]);

    const [showAddServiceCustomerID, setShowAddServiceCustomerID] = useState(false);

    // Lấy ra dữ liệu các dịch vụ
    useEffect(() => {
        const getData = async () => {
            const dataService = await bookingApi.getData();
            console.log(dataService);
            setData(dataService);
        };
        getData();
    }, []);

    // Lấy ra dữ liệu các dịch vụ
    const getDataTable = async () => {
        const dataTableNew = await bookingApi.getCustomerCalender(calender);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTable(dataStt);
    };

    useEffect(() => {
        if (calender) {
            getDataTable();
        }
    }, [calender]);

    // table
    const columns = [
        { field: 'stt', headerName: 'STT', width: 50, flex: 0.5 },
        { field: 'id', headerName: 'Mã', minWidth: 100, flex: 1 },
        { field: 'customerName', headerName: 'Tên', minWidth: 150, flex: 1.5 },
        { field: 'birthday', headerName: 'Ngày sinh', minWidth: 90, flex: 1 },
        { field: 'phone', headerName: 'Liên hệ', minWidth: 120, flex: 1 },
        { field: 'pack', headerName: 'Gói khám', minWidth: 150, flex: 1.5 },
        // { field: 'time_go', headerName: 'Đến khám', minWidth: 90, flex: 1 },
        {
            field: 'confirm',
            headerName: 'Xác nhận',
            minWidth: 90,
            flex: 1,
            renderCell: (params) => (
                <div
                    style={{
                        color: params.value == 'Done' ? 'blue' : params.value == 'No' ? '#ff6000' : 'red',
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        { field: 'note', headerName: 'Lý do khám', minWidth: 200, flex: 1 },
        { field: 'admin', headerName: 'Đăng kí', minWidth: 80, flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => (
                <>
                    <EditIcon
                        onClick={() => updateSubmit(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <CreditScoreIcon
                        onClick={() => {
                            confirmCustomer(params.row.id, params.row.admin, params.row.confirm);
                        }}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    {/* <AccessAlarmIcon
                        onClick={() => openClock(params.row.id)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    /> */}
                    <Settings
                        onClick={() => handleOpenCustomerBookingID(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => deleteBookingCustomerId(params.row)} style={{ cursor: 'pointer' }} />
                </>
            ),
        },
    ];

    const deleteBookingCustomerId = async (data) => {
        var rule = localStorage.getItem('rule');
        var userName = localStorage.getItem('user');
        if ((userName === data.admin && data.confirm !== 'Done') || rule === 'admin') {
            const { isConfirmed } = await Swal.fire({
                title: 'Delete ?',
                text: data.customerName,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            });
            if (isConfirmed) {
                const resultRemove = await bookingApi.deleteBookingCustomerId(data.id, calender);
                if (resultRemove) {
                    Swal.fire('Deleted!', data.serviceID, 'success');
                    getDataTable();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Something went wrong!',
                    });
                }
            }
        } else {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: 'Bạn không có đủ quyền !',
            });
        }
    };

    const updateSubmit = (data) => {
        var rule = localStorage.getItem('rule');
        var userName = localStorage.getItem('user');

        console.log(data);
        if (data.admin === userName || rule === 'admin') {
            setTitleForm('Update New');
            setOriginForm({
                data: formDataOrigin,
                condition: condition,
            });
            setDefaultValues(data);
            setConfirmForm({ confirm: editCustomer });
            setShowForm(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Bạn không có quyền sửa khách hàng này!',
            });
        }
    };

    const editCustomer = async (data) => {
        const result = await bookingApi.editCustomer({ ...data, calender });
        console.log(result);
        if (result.result) {
            Swal.fire('Edit Customer!', 'Edit success !', 'success');
            getDataTable();
            setShowForm(false);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                // text: result.message,
            });
        }
    };

    const check = async () => {
        if (dataService.length > 0) {
            const idMap = {};
            var filteredArray = [];
            for (const item of dataService) {
                if (!idMap[item.id]) {
                    idMap[item.id] = true;
                    filteredArray.push(item);
                }
            }
            console.log(filteredArray);
            setDataDate(await bookingApi.check(filteredArray));
        }
    };

    const addData = async (data) => {
        if (data) {
            if (data.type === 'Pack') {
                const dataPackNew = await bookingApi.getPackID(data);
                console.log('dataPackNew: ');
                console.log(dataPack);
                setDataPack([...dataPack, data]);
                setDataService([...dataService, ...dataPackNew]);
                // var dataNew = dataPackNew; check1
            } else {
                setDataService([...dataService, data]);
            }
        }
    };

    const handleDate = (e) => {
        setDateSelect(e.target.getAttribute('value'));
        setShowServiceInfo(true);
        setSatusDate(e.target.getAttribute('status'));
    };

    // Đăng kí
    const handleRegister = async (e) => {
        // show
        var rule = localStorage.getItem('rule');
        if (satusDate === 'erro' && rule !== 'admin') {
            const result = await Swal.fire({
                title: 'Đăng kí ?',
                text: 'Không đủ điều kiện đăng kí, Đăng kí sẽ được thêm vào hàng chờ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Xác nhận',
            });
            console.log(result);
            if (result.isConfirmed) {
                setconfirmRegister(false);
                setShowServiceInfo(false);
                setShowRegister(true);
            }
        } else {
            setconfirmRegister(true);
            setShowServiceInfo(false);
            setShowRegister(true);
        }
    };

    const openClock = (customerID) => {
        var rule = localStorage.getItem('rule');
        if (rule === 'admin') {
            setTimeSelect('');
            setShowClock(true);
            setCustomerSelect(customerID);
        } else {
            Swal.fire('Set Time!', `Bạn không đủ quyền. Vui lòng liên hệ admin !`, 'error');
        }
    };

    const confirmClock = async () => {
        const confirm = await bookingApi.setClock(customerSelect, timeSelect, calender);
        if (confirm.result === true) {
            Swal.fire('Set Time!', `Success!`, 'success');
            setShowClock(false);
            getDataTable();
        } else {
            Swal.fire('Set Time!', `Erro!`, 'error');
            getDataTable();
        }
    };

    const confirmCustomer = async (customerID, adminName, confirmValue) => {
        console.log('customerID:  ' + customerID);
        console.log('calender:  ' + calender);
        console.log('adminName:  ' + adminName);
        var rule = localStorage.getItem('rule');
        // var userName = localStorage.getItem('user');
        if (rule === 'admin' || rule === 'adminSale') {
            const result = await Swal.fire({
                title: 'Xác nhận đăng kí',
                text: 'Bạn có chắc chắn xác nhận đăng kí cho khách hàng này!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Xác nhận',
            });
            if (result.isConfirmed) {
                const confirm = await bookingApi.confirmCustomer(customerID, calender, confirmValue);
                if (confirm.result === true) {
                    Swal.fire('Confirm customer!', `Success!`, 'success');
                    setShowClock(false);
                    getDataTable();
                } else {
                    Swal.fire('Confirm customer!', `Erro!`, 'error');
                    getDataTable();
                }
            }
        } else {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: 'Bạn không có đủ quyền !',
            });
        }
    };

    const handleDenleteService = (e) => {
        const id = e.currentTarget.dataset.value;
        const array = dataService.filter((item) => item.id !== id);
        setDataService([...array]);
    };

    const clearPack = (e) => {
        const id = e.currentTarget.dataset.value;

        const array = dataPack.filter((item) => item.id !== id);
        setDataPack([...array]);

        const array2 = dataService.filter((item) => item.packID !== id);
        setDataService([...array2]);

        // console.log(dataService);
        // setDataPack([]);
        // setDataService([]);
    };

    const confirmFormRegister = async (data) => {
        var user = localStorage.getItem('user');
        console.log(user);
        if (data.customerID === '') {
            var randomID = Math.floor(Math.random() * 900000 + 100000);
            randomID = 'KHrD' + randomID;
            data.customerID = randomID;
        }

        bookingApi.addCustomer(data);
        if (confirmRegister) {
            bookingApi.addCalender({
                service: dataService,
                date: dateSelect,
                customerID: data.customerID,
                user,
            });
        } else {
            bookingApi.addCalenderWait({
                service: dataService,
                date: dateSelect,
                customerID: data.customerID,
                user,
            });
        }

        setShowRegister(false);
        const { isConfirmed } = await Swal.fire(
            'Đăng kí',
            `Đăng kí khách hàng ${data.customerID} thành công !`,
            'success',
        );
        if (isConfirmed) {
            window.location.reload();
        }
    };

    const handleOpenCustomerBookingID = (data) => {
        var rule = localStorage.getItem('rule');
        var userName = localStorage.getItem('user');
        console.log(data);
        if (data.admin === userName || rule === 'admin') {
            setCustomerSelect(data.id);
            getCustomerBookingID(data.id);
            setShowDialogPage(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Bạn không có quyền sửa khách hàng này!',
            });
        }
    };

    // call data table 2
    const getCustomerBookingID = async (id) => {
        const dataTableNew = await bookingApi.getCustomerBookingID(id, calender);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTableCustomerID(dataStt);
    };

    // Table 2
    const columnsCustomerId = [
        { field: 'stt', headerName: 'STT', flex: 0.5 },
        { field: 'serviceID', headerName: 'serviceID', flex: 1 },
        { field: 'serviceName', headerName: 'serviceName', flex: 3 },
        { field: 'serviceTime', headerName: 'serviceTime', flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <DeleteIcon
                        onClick={() => deleteBookingCustomerIdServiceID(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                </>
            ),
        },
    ];

    const deleteBookingCustomerIdServiceID = async (data) => {
        const { isConfirmed } = await Swal.fire({
            title: 'Delete ?',
            text: data.customerName,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });

        if (isConfirmed) {
            const resultRemove = await bookingApi.deleteBookingCustomerIdServiceID(
                customerSelect,
                calender,
                data.serviceID,
            );
            if (resultRemove) {
                Swal.fire('Deleted!', data.serviceID, 'success');
                getCustomerBookingID(customerSelect);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong!',
                });
            }
        }
    };

    // add new
    const openAddNewSubmit = () => {
        var rule = localStorage.getItem('rule');
        setShowAddServiceCustomerID(true);
        // if (rule === 'admin') {
        //     setShowAddServiceCustomerID(true);
        // }
        // else {
        //     Swal.fire({
        //         icon: 'Error',
        //         title: 'Error',
        //         text: 'Bạn không có đủ quyền !',
        //     });
        // }
    };

    const addNewSubmit = async (data) => {
        var rule = localStorage.getItem('rule');
        const confirm = await bookingApi.addCustomerService({
            ...data,
            customerID: customerSelect,
            dateSelect: calender,
            rule,
        });

        if (confirm.result === true) {
            Swal.fire('Add Service!', `Success!`, 'success');
            setShowAddServiceCustomerID(false);
            getCustomerBookingID(customerSelect);
        } else {
            Swal.fire('Add Service!', confirm.message, 'error');
        }
    };

    return (
        <div>
            <AddServiceCustomer
                confirmForm={addNewSubmit}
                show={showAddServiceCustomerID}
                setShow={setShowAddServiceCustomerID}
            />
            <DialogPage
                show={showDialogPage}
                setShow={setShowDialogPage}
                id={customerSelect}
                columns={columnsCustomerId}
                dataTable={dataTableCustomerID}
                setDataTable={setDataTableCustomerID}
                AddNew={openAddNewSubmit}
            />
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultValues}
                        formDataOrigin={originForm.data}
                        title={titleForm}
                        condition={originForm.condition}
                        setDataTable={setDataTable}
                    />
                )}
            </div>
            <Register show={showRegister} setShow={setShowRegister} confirmForm={confirmFormRegister} />

            <Clock
                confirmForm={confirmClock}
                show={showClock}
                setShow={setShowClock}
                timeSelect={timeSelect}
                setTimeSelect={setTimeSelect}
            />

            <ServiceInfo
                show={showServiceInfo}
                setShow={setShowServiceInfo}
                dateSelect={dateSelect}
                dataService={dataService}
                handleRegister={handleRegister}
            />

            <div className={style.between}>
                <SearchData data={data} addData={addData} />
                <div className={style.buttonCheck}>
                    <Button onClick={check} variant="contained" color="primary" startIcon={<ContentPasteSearch />}>
                        Check
                    </Button>
                </div>
            </div>
            <div className={style.dateAll}>
                {dataDate.map((value, index) => (
                    <div
                        style={{ background: value.color }}
                        key={index}
                        className={`${style.dateSelect} ${value.date === dateSelect ? style.activate : ''}`}
                        value={value.date}
                        status={value.color === '#61CE70' ? 'ok' : 'erro'}
                        onClick={handleDate}
                    >
                        {value.date}
                    </div>
                ))}
            </div>

            <div>
                {dataPack.map((value, index) => (
                    <div key={index} value={index} className={style.boxPack}>
                        <div>
                            {value.type} : {value.label}
                        </div>
                        <div>
                            <IconButton data-value={value.id} onClick={clearPack}>
                                <MedicalServicesIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}

                {/* {dataService.map((value, index) => (
                    <div key={index} className={style.boxService}>
                        <div>
                            {value.id} : {value.label}
                        </div>
                        <div>
                            <IconButton data-value={index} onClick={handleDenleteService}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                ))} */}

                {dataService.map((value, index) => {
                    var check = true;
                    for (var i = 0; i < index; i++) {
                        if (value.id === dataService[i].id) {
                            check = false;
                        }
                    }
                    console.log(check);
                    if (check)
                        return (
                            <div key={index} className={style.boxService}>
                                <div>
                                    {value.id} : {value.label}
                                </div>
                                <div>
                                    <IconButton data-value={value.id} onClick={handleDenleteService}>
                                        <Close />
                                    </IconButton>
                                </div>
                            </div>
                        );
                })}
            </div>
            <div>
                <CalendarData setCalender={setCalender} />
            </div>
            <div style={{ marginTop: '20px' }}>
                <h3>{calender}</h3>
            </div>

            <div>
                <TodoTable rows={dataTable} columns={columns} />
            </div>
        </div>
    );
}

export default Booking;
