import axiosClinet from '~/api/axiosClient';

const serviceCalenderApi = {
    getAll(calender) {
        const url = '/serviceCalender/getserviceCalender';
        return axiosClinet.get(url, {
            params: {
                calender,
            },
        });
    },
    getDoctor() {
        const url = '/serviceCalender/getdoctor';
        return axiosClinet.get(url);
    },
    getServiceCalenderID(id, calender) {
        console.log(id);
        const url = '/serviceCalender/getserviceCalenderid';
        return axiosClinet.get(url, {
            params: {
                id,
                calender,
            },
        });
    },
    add(data) {
        console.log(data);
        const url = '/serviceCalender/addserviceCalender';
        return axiosClinet.post(url, data);
    },
    update(data) {
        const url = '/serviceCalender/updateserviceCalender';
        return axiosClinet.put(url, data);
    },
    updateServiceCalenderItem(data) {
        console.log(data);
        const url = '/serviceCalender/updateserviceCalenderitem';
        return axiosClinet.put(url, data);
    },
    remove(id, calender) {
        console.log(id);
        const url = '/serviceCalender/removeserviceCalender';
        return axiosClinet.delete(url, { data: { id, calender } });
    },
    removeServiceCalenderItem(id) {
        console.log(id);
        const url = '/serviceCalender/removeserviceCalenderitem';
        return axiosClinet.delete(url, { data: { id } });
    },
};

export default serviceCalenderApi;
