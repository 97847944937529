import { Booking } from '~/page/Booking';
import { Calendar } from '~/page/Calendar';
import { Customer } from '~/page/Customer';
import { Doctor } from '~/page/Doctor';
import { Service } from '~/page/Service';
import { Pack } from '~/page/Pack';
import { PrivatePage } from '~/page/PrivatePage';
import { Login } from '~/Ath/login';
import { RegisterWin } from '~/Ath/registerwin';
import { CheckWin } from '~/Ath/checkwin1';
import { Account } from '~/page/Account';

import PrivateLayout from '~/Layouts/PrivateLayout';
import NullLayout from '~/Layouts/NullLayout';

const publickRoutes = [
    { path: '/login', component: Login, layout: NullLayout },
    { path: '/registerwin', component: RegisterWin, layout: NullLayout },
    { path: '/checkwin', component: CheckWin, layout: NullLayout },
];

const privateRoutes = [
    { path: '/', component: Booking, rule: 'all' },
    { path: '/calendar', component: Calendar, rule: 'all' },
    // { path: '/customer', component: Customer },
    { path: '/doctor', component: Doctor, rule: 'all' },
    // { path: '/service', component: Service },
    { path: '/servicepack', component: Pack, rule: 'admin' },
    { path: '/account', component: Account, rule: 'admin' },
    { path: '/private', component: PrivatePage, layout: PrivateLayout, rule: 'admin' },
];

export { publickRoutes, privateRoutes };
