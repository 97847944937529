import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { TextField, Button, FormControlLabel, Checkbox, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from './img/logo.png';
import win from './img/win.svg'; 
import { msalInstance } from "./authConfig";

import { myIp, login, loginWin } from '~/api/auth/auth.js';
import styles from './Login.module.scss';

function Login() {
    const [username, setUsername] = useState(localStorage.getItem('username') ? localStorage.getItem('username') : '');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [notification, setNotification] = useState('');
    const [ip, setIp] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(() => (localStorage.getItem('username') ? true : false));

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const getMyIp = async () => {
            setIp(await myIp());
        };

        getMyIp();
    }, []);

    useEffect(() => {
        msalInstance.handleRedirectPromise().then(async(response) => { 
          if (response !== null && response.account !== null) {
            setUsername(response.account.username)
            const data = {
                username: response.account.username,
                name: response.account.name,
                myIp: ip,
                tenantId: response.account.tenantId,
            }; 
            const checkLogin = await loginWin(data);
            console.log(checkLogin);
    
            if (checkLogin) {
                localStorage.setItem('token', checkLogin.token);
                localStorage.setItem('user', checkLogin.username);
                localStorage.setItem('rule', checkLogin.rule);
    
                // Lưu tài khoản
                checked ? localStorage.setItem('username', username) : localStorage.removeItem('username');
                setToken(checkLogin);
            } else {
                setNotification('Thông tin tài khoản mật khẩu không chính xác!');
            }
          }
        });
      }, []);
    // Login
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(ip);
        // Xử lý đăng nhập
        const data = {
            username,
            password,
            myIp: ip,
        };
        const checkLogin = await login(data);
        console.log(checkLogin);

        if (checkLogin) {
            localStorage.setItem('token', checkLogin.token);
            localStorage.setItem('user', checkLogin.username);
            localStorage.setItem('rule', checkLogin.rule);

            // Lưu tài khoản
            checked ? localStorage.setItem('username', username) : localStorage.removeItem('username');
            setToken(checkLogin);
        } else {
            setNotification('Thông tin tài khoản mật khẩu không chính xác!');
        }
    };

    const checkAcc = (event) => {
        setChecked(event.target.checked);
        console.log(event.target.checked);
    };

    const handleLoginWin =  async() => {
        const loginResponse = await msalInstance.loginRedirect();
    }

    return (
        <div className={styles.body}>
            {token ? (
                <Navigate to="/" />
            ) : (
                <div className={styles.formLogin}>
                    <img src={logo} alt="logo" />
                    <form onSubmit={handleSubmit}>
                        <div>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Tài khoản"
                                type="text"
                                id="username"
                                name="username"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                onChange={(event) => setPassword(event.target.value)}
                                label="Mật khẩu"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox onChange={checkAcc} checked={checked} value="remember" color="primary" />
                            }
                            label="Remember me"
                        />
                        <div>
                            <Button type="submit" variant="contained" fullWidth>
                                Đăng nhập
                            </Button>
                        </div>
                        <div  style={{marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center"}}  onClick={handleLoginWin}>
                            <div  style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <p><img width={25} src={win} alt="win" /></p> <p style={{color: "#1976d2", fontWeight : "500", fontSize: 14, cursor: "pointer"}}> Đăng nhập bằng Microsoft</p>
                            </div>
                        </div>

                        <div className="notification"> {notification}</div>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Login;
