import { useState, useEffect } from 'react';
import * as yup from 'yup';
// Giao diện
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Settings from '@mui/icons-material/Settings';
import Swal from 'sweetalert2';
import { AddBox } from '@mui/icons-material';

import CalendarData from '~/components/CalendarData/CalendarData';
import ImportExcel from '../compoents/ImportExcel/ImportExcel.js';
import serviceApi from '~/api/service/serviceApi.js';
import calenderApi from '~/api/calender/calenderApi.js';
import DialogPage from '~/page/compoents/DialogPage2/DialogPage.js';
import TodoTable from '~/page/compoents/TodoTable/TodoTable.js';
import TodoForm from '../compoents/TodoForm/TodoForm.js';
import AddDoctor from '../compoents/AddDoctor/AddDoctor.js';
import { Button } from '@mui/material';

// Origin
// import data excel
const dataTitleExcel = ['doctorID', 'serviceDate', 'hourStart', 'hourEnd'];
//  input form Calender
const formDataOrigin = [
    { name: 'doctorID', label: 'Mã bác sĩ', type: 'text' },
    { name: 'hourStart', label: 'Thời gian bắt đầu', type: 'text' },
    { name: 'hourEnd', label: 'Thời gian kết thúc', type: 'text' },
];

// Điều kiện
const condition = yup.object().shape({
    doctorID: yup.string().required('Vui lòng nhập dữ liệu!'),
});
// // origin input form CalenderID
// const formDataOriginCalenderID = [{ name: 'serviceID', label: 'Mã dịch vụ:', type: 'text' }];

// const conditionCalenderID = yup.object().shape({
//     serviceID: yup.string().required('Vui lòng nhập dữ liệu!'),
// });

// origin input form addNEw item Calender
const formDataOriginAddCalenderID = [{ name: 'serviceID', label: 'Mã dịch vụ:', type: 'text' }];
const conditionAddCalenderID = yup.object().shape({
    serviceID: yup.string().required('Vui lòng nhập dữ liệu!'),
});

function Calendar() {
    console.log('check Loop');
    // Ngay
    const [calender, setCalender] = useState();
    // data table 1
    const [dataTable, setDataTable] = useState([]);
    const [dataTableCalenderID, setDataTableCalenderID] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [originForm, setOriginForm] = useState({
        data: formDataOrigin,
        condition: condition,
    });
    const [defaultValues, setDefaultValues] = useState();
    const [titleForm, setTitleForm] = useState('Add New');
    const [confirmForm, setConfirmForm] = useState({ confirm: serviceApi.add });

    // page
    const [CalenderID, setCalenderID] = useState('');
    const [showDialogPage, setShowDialogPage] = useState(false);
    // search
    // const [dataSearch, setDataSearch] = useState();
    const [showAddDoctor, setShowAddDoctor] = useState(false);
    console.log(calender);

    const AddNewDoctorCalender = () => {
        setShowAddDoctor(true);
        // setTitleForm('Update New');
        // setOriginForm({
        //     data: formDataOrigin,
        //     condition: condition,
        // });
        // setDefaultValues({});
        // setConfirmForm({ confirm: AddNewDoctorCalenderApi });
        // setShowForm(true);
    };

    const AddNewDoctorCalenderApi = async (data) => {
        const result = await calenderApi.add({ ...data, ...{ serviceDate: calender } });
        console.log(result);
        if (result.result) {
            setShowAddDoctor(false);
            Swal.fire('Add New!', 'Add New success !', 'success');
            getDataTable();
        } else {
            // setShowForm(false);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: result.message,
            });
        }
    };

    // call data table 1
    const getDataTable = async () => {
        const dataTableNew = await calenderApi.getAll(calender);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTable(dataStt);
    };

    useEffect(() => {
        if (calender) {
            getDataTable();
        }
    }, [calender]);

    // table 1
    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 0.5 },
        { field: 'id', headerName: 'Tên bác sĩ', minWidth: 280, flex: 4 },
        { field: 'doctorGroup', headerName: 'doctorGroup', minWidth: 180, flex: 4 },
        { field: 'hour', headerName: 'Thời gian', minWidth: 180, flex: 4 },
        { field: 'timeJobDoctor', headerName: 'Dự kiến', minWidth: 120, flex: 2 },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 120,
            flex: 3,
            renderCell: (params) => (
                <div>
                    <EditIcon
                        onClick={() => updateSubmit(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <Settings
                        onClick={() => handleOpenCalenderId(params.row.doctorID)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removeTable(params.row)} style={{ cursor: 'pointer' }} />
                </div>
            ),
        },
    ];

    // Edit time
    const updateSubmit = (data) => {
        var rule = localStorage.getItem('rule');
        if (rule === 'admin') {
            setTitleForm('Update New');
            setOriginForm({
                data: formDataOrigin,
                condition: condition,
            });
            setDefaultValues(data);
            setConfirmForm({ confirm: updateTable });
            setShowForm(true);
        }
    };

    const updateTable = async (data) => {
        var rule = localStorage.getItem('rule');
        if (rule === 'admin') {
            const result = await calenderApi.update({ ...data, calender });
            if (result.result) {
                setShowForm(false);
                Swal.fire('Update!', 'Update success !', 'success');
                getDataTable();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: result.message,
                });
            }
        }
    };

    // Function in Table Calender
    const removeTable = async (data) => {
        var rule = localStorage.getItem('rule');
        if (rule === 'admin') {
            const { isConfirmed } = await Swal.fire({
                title: 'Delete ?',
                text: data.id,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            });
            if (isConfirmed) {
                const resultRemove = await calenderApi.remove(data.doctorID, calender);
                if (resultRemove) {
                    Swal.fire('Deleted!', data.id, 'success');
                    getDataTable();
                } else {
                    Swal.fire({
                        icon: 'Error',
                        title: 'Error',
                        text: 'Something went wrong!',
                    });
                }
            }
        }
    };

    // call data table 2
    const handleOpenCalenderId = (id) => {
        var rule = localStorage.getItem('rule');
        if (rule === 'admin') {
            setCalenderID(id);
            getDataTableCalenderID(id);
            setShowDialogPage(true);
        }
    };

    const getDataTableCalenderID = async (id) => {
        const dataTableNew = await calenderApi.getServiceCalenderID(id, calender);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTableCalenderID(dataStt);
    };
    // table 2
    const columnsCalenderId = [
        { field: 'stt', headerName: 'STT', flex: 0.5 },
        { field: 'serviceID', headerName: 'serviceID', flex: 1 },
        { field: 'serviceName', headerName: 'serviceID', flex: 3 },
        { field: 'serviceTime', headerName: 'serviceID', flex: 1 },
        // {
        //     field: 'Actions',
        //     headerName: 'Actions',
        //     flex: 1,
        //     renderCell: (params) => (
        //         <>
        //             <DeleteIcon
        //                 onClick={() => removeTableCalenderId(params.row)}
        //                 style={{ cursor: 'pointer', marginRight: '8px' }}
        //             />
        //         </>
        //     ),
        // },
    ];

    // fun

    // const removeTableCalenderId = async (data) => {
    //     // console.log(data);
    //     const { isConfirmed } = await Swal.fire({
    //         title: 'Delete ?',
    //         text: data.serviceID,
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!',
    //     });

    //     if (isConfirmed) {
    //         const resultRemove = await calenderApi.removeServiceCalenderItem(data.id);
    //         if (resultRemove) {
    //             Swal.fire('Deleted!', data.serviceID, 'success');
    //             getDataTableCalenderID(CalenderID);
    //         } else {
    //             Swal.fire({
    //                 icon: 'Error',
    //                 title: 'Error',
    //                 text: 'Something went wrong!',
    //             });
    //         }
    //     }
    // };

    // add data
    const addNewSubmit = () => {
        setTitleForm('Update New');
        setOriginForm({
            data: formDataOriginAddCalenderID,
            condition: conditionAddCalenderID,
        });
        setDefaultValues({});
        setConfirmForm({ confirm: addNew });
        setShowForm(true);
    };

    const addNew = async (data) => {
        console.log(data);
        const result = await serviceApi.add(data);
        console.log(result);
        if (result.result) {
            setShowForm(false);
            Swal.fire('Add New!', 'Add New success !', 'success');
            getDataTable();
        } else {
            // setShowForm(false);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: result.message,
            });
        }
    };

    return (
        <div>
            <AddDoctor confirmForm={AddNewDoctorCalenderApi} show={showAddDoctor} setShow={setShowAddDoctor} />
            <CalendarData setCalender={setCalender} />
            <div style={{ marginTop: '40px' }}>
                <div className="between">
                    <h3>{calender}</h3>

                    {localStorage.getItem('rule') === 'admin' && (
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '10px', display: 'flex' }}>
                                <ImportExcel
                                    add={calenderApi.add}
                                    dataTitleExcel={dataTitleExcel}
                                    loadData={getDataTable}
                                />

                                <div style={{ marginLeft: '10px' }}>
                                    <Button
                                        sx={{ marginRight: '10px' }}
                                        onClick={AddNewDoctorCalender}
                                        variant="contained"
                                        component="label"
                                        startIcon={<AddBox />}
                                    >
                                        Add New
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {showForm && (
                        <TodoForm
                            show={showForm}
                            setShow={setShowForm}
                            confirmForm={confirmForm.confirm}
                            defaultValues={defaultValues}
                            formDataOrigin={originForm.data}
                            title={titleForm}
                            condition={originForm.condition}
                            setDataTable={setDataTable}
                        />
                    )}
                </div>
                <div>
                    <TodoTable rows={dataTable} columns={columns} />
                    <DialogPage
                        show={showDialogPage}
                        setShow={setShowDialogPage}
                        id={CalenderID}
                        columns={columnsCalenderId}
                        dataTable={dataTableCalenderID}
                        setDataTable={setDataTableCalenderID}
                        AddNew={addNewSubmit}
                    />
                </div>
            </div>
        </div>
    );
}

export default Calendar;
