// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TodoTable_todoTable__ZmjA7 {\n  width: 100%;\n  height: 86vh;\n}\n\n@media only screen and (max-width: 800px) {\n  .TodoTable_todoTable__ZmjA7 {\n    width: 100%;\n    height: 100vh;\n  }\n}", "",{"version":3,"sources":["webpack://./src/page/compoents/TodoTable/TodoTable.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI;IACI,WAAA;IACA,aAAA;EACN;AACF","sourcesContent":[".todoTable {\n    width: 100%;\n    height: 86vh;\n}\n\n@media only screen and (max-width: 800px) {\n    .todoTable {\n        width: 100%;\n        height: 100vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"todoTable": "TodoTable_todoTable__ZmjA7"
};
export default ___CSS_LOADER_EXPORT___;
