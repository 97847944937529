import axios from 'axios';
// import axiosClinet from '~/api/axiosClient';

export const myIp = async () => {
    try {
        const res = await axios.get('https://api.ipify.org?format=json');
        return res.data.ip;
    } catch (error) {
        console.log(error);
    }
};

export const login = async (data) => {
    try {
        const res = await axios.post('https://bookingapi.t-matsuoka.com/auth/login', data);
        return res.data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const loginWin = async (data) => {
    console.log('login Win');
    try {
        const res = await axios.post('https://bookingapi.t-matsuoka.com/auth/loginwin', data);
        return res.data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const checkLoginSessions = async () => {
    try {
        const isToken = localStorage.getItem('token');
        const data = { token: isToken };
        const res = await axios.post('https://bookingapi.t-matsuoka.com/auth/loginsesions', data);
        return res.data.login;
    } catch (error) {
        console.log(error);
        return false;
    }
};
