import axios from 'axios';

export const serverMain = 'https://bookingapi.t-matsuoka.com/';

const axiosClinet = axios.create({
    baseURL: 'https://bookingapi.t-matsuoka.com/',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Intercept request
axiosClinet.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['token'] = `${token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

axiosClinet.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        return {
            result: false,
            message: 'Not connect server!',
        };
    },
);

export default axiosClinet;
