// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddServiceCustomer_search__7qb0u {\n  width: 500px;\n  padding: 20px;\n}\n\n@media only screen and (max-width: 600px) {\n  .AddServiceCustomer_search__7qb0u {\n    width: 375px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/page/compoents/AddServiceCustomer/AddServiceCustomer.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ;;AAEA;EACI;IACI,YAAA;EACN;AACF","sourcesContent":[".search {\n    width: 500px;\n    padding: 20px;\n}\n\n@media only screen and (max-width: 600px) {\n    .search {\n        width: 375px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "AddServiceCustomer_search__7qb0u"
};
export default ___CSS_LOADER_EXPORT___;
