import React from 'react';
import { Button, Dialog } from '@mui/material';
import style from './Clock.module.scss';

var timeDate = [];

for (let hour = 8; hour <= 17; hour++) {
    for (let minute = 0; minute <= 30; minute += 30) {
        timeDate.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`);
    }
}
function Clock({ confirmForm, show, setShow, timeSelect, setTimeSelect }) {
    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };

    const handleTime = (e) => {
        console.log(e.target.getAttribute('value'));
        setTimeSelect(e.target.getAttribute('value'));
    };

    const handleSubmit = () => {
        confirmForm();

    }

    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div style={{ padding: '20px' }}>
                <h3>Thời gian đến khám:</h3>
                <div className={style.timeAll}>
                    {timeDate.map((value, index) => (
                        <div
                            key={index}
                            className={`${style.timeSelect} ${value === timeSelect ? style.activate : ''}`}
                            value={value}
                            onClick={handleTime}
                        >
                            {value}
                        </div>
                    ))}
                </div>

                <Button type="submit" variant="contained" fullWidth color="primary" sx={{ padding: '10px 0px', marginTop: "20px" }} onClick={handleSubmit}>
                    Xác nhận
                </Button>
            </div>

        </Dialog>
    )
}

export default Clock;
