import React from 'react';
import { Button, Dialog, TextField } from '@mui/material';
import SearchData2 from '../SearchData2/SearchData2';
import { useEffect, useState } from 'react';
import calenderApi from '~/api/calender/calenderApi.js';
import style from './AddDoctor.module.scss';

function AddDoctor({ confirmForm, show, setShow }) {
    const [data, setData] = useState([]);
    const [doctor, setDoctor] = useState();

    const [hourStart, setHourStart] = useState('');
    const [hourEnd, setHourEnd] = useState('');

    // Lấy ra dữ liệu các dịch vụ
    useEffect(() => {
        const getData = async () => {
            var data = await calenderApi.getDoctor();
            console.log(data);
            setData(data);
        };
        getData();
    }, []);
    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };

    const addData = async (data) => {
        if (data) {
            setDoctor(data);
        }
    };

    const handleSubmit = () => {
        confirmForm({ ...doctor, hourStart, hourEnd });
        setDoctor('');
        setHourStart('');
        setHourStart('');
    };

    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div className={style.search}>
                <SearchData2 data={data} addData={addData} />

                <div style={{ marginTop: '10px' }}>
                    <TextField
                        label="Thời gian bắt đầu"
                        type="text"
                        fullWidth
                        id="hourStart"
                        name="hourStart"
                        value={hourStart}
                        onChange={(event) => setHourStart(event.target.value)}
                    />
                </div>

                <div style={{ marginTop: '10px' }}>
                    <TextField
                        label="Thời gian kết thúc"
                        type="text"
                        fullWidth
                        id="hourEnd"
                        name="hourEnd"
                        value={hourEnd}
                        onChange={(event) => setHourEnd(event.target.value)}
                    />
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={{ padding: '10px 0px', marginTop: '20px' }}
                    onClick={handleSubmit}
                >
                    Xác nhận
                </Button>
            </div>
        </Dialog>
    );
}

export default AddDoctor;
