import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button, Dialog } from '@mui/material';
import * as yup from 'yup';

// import style from './Register.module.scss';

var timeDate = [];

for (let hour = 8; hour <= 17; hour++) {
    for (let minute = 0; minute <= 30; minute += 30) {
        timeDate.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`);
    }
}
// defaultValues: Giá trị khởi tạo
// formDataOrigin: trường biến
// condition: Yup trường điều kiện của biến
// title: Tên form
// showForm: trạng thái hiển thị form
// setShowForm: Hàm trạng thái form
// loadData: Load lại data
//  input form
const formDataOrigin = [
    { name: 'customerID', label: 'Mã khách hàng', type: 'text' },
    { name: 'customerName', label: 'Tên Khách hàng', type: 'text' },
    { name: 'customerDate', label: 'Năm sinh', type: 'text' },
    { name: 'customerPhone', label: 'Liên hệ', type: 'text' },
    { name: 'note', label: 'Lý do khám', type: 'text' },
];
// Điều kiện form
const condition = yup.object().shape({
    customerName: yup.string().required('Vui lòng nhập dữ liệu!'),
    customerDate: yup.string().required('Vui lòng nhập dữ liệu!'),
    customerPhone: yup.string().required('Vui lòng nhập dữ liệu!'),
    note: yup.string().required('Vui lòng nhập dữ liệu!'),
});

function Register({ confirmForm, show, setShow }) {
    console.log('check Loop Register');
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(condition),
    });

    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };

    // Giao diện
    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div style={{ padding: '20px' }}>
                {/* <h3>Thời gian đến khám:</h3> */}

                {/* <div className={style.timeAll}>
                    {timeDate.map((value, index) => (
                        <div
                            key={index}
                            className={`${style.timeSelect} ${value === timeSelect ? style.activate : ''}`}
                            value={value}
                            onClick={handleTime}
                        >
                            {value}
                        </div>
                    ))}
                </div> */}

                <h3 style={{ marginTop: '20px' }}>Thông tin khách hàng:</h3>
                <form onSubmit={handleSubmit(confirmForm)}>
                    {formDataOrigin.map((value, index) => (
                        <Controller
                            key={index}
                            name={value.name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    sx={{ marginBottom: '20px' }}
                                    fullWidth
                                    label={value.label}
                                    type={value.type}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors[value.name]}
                                    helperText={errors[value.name]?.message}
                                    {...field}
                                />
                            )}
                        />
                    ))}

                    <Button type="submit" variant="contained" fullWidth color="primary" sx={{ padding: '10px 0px' }}>
                        Xác nhận
                    </Button>
                </form>
            </div>
        </Dialog>
    );
}

export default Register;
