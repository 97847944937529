import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { publickRoutes } from '~/routes';
import { privateRoutes } from '~/routes';
import DefaultLayout from '~/Layouts/DefaultLayout';
import MyProvider from '~/Global';
import ThemeMui from './ThemeMui';

function App() {
    return (
        <MyProvider>
            <ThemeMui>
                <Router>
                    {/* <GlobalContext.Provider value={[globalState, setGlobalState]}> */}
                    <div className="App">
                        <Routes>
                            {publickRoutes.map((value, index) => {
                                const Layout = value.layout || DefaultLayout;
                                const Page = value.component;
                                return (
                                    <Route
                                        key={index}
                                        path={value.path}
                                        element={
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        }
                                    />
                                );
                            })}

                            {privateRoutes.map((value, index) => {
                                const Layout = value.layout || DefaultLayout;
                                const Page = value.component;
                                var rule = localStorage.getItem('rule');
                                if (value.rule === 'all' || value.rule === rule) {
                                    console.log(value.path);
                                    return (
                                        <Route
                                            key={index}
                                            path={value.path}
                                            element={
                                                <Layout>
                                                    <Page />
                                                </Layout>
                                            }
                                        />
                                    );
                                }
                            })}
                        </Routes>
                    </div>
                    {/* </GlobalContext.Provider> */}
                </Router>
            </ThemeMui>
        </MyProvider>
    );
}

export default App;
