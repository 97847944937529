import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button, Dialog } from '@mui/material';

// defaultValues: Giá trị khởi tạo
// formDataOrigin: trường biến
// condition: Yup trường điều kiện của biến
// title: Tên form
// showForm: trạng thái hiển thị form
// setShowForm: Hàm trạng thái form
// loadData: Load lại data

function TodoForm({ defaultValues, formDataOrigin, condition, title, confirmForm, show, setShow }) {
    console.log('check Loop');
    console.log(confirmForm);
    // useForm
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(condition),
        defaultValues,
    });

    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };

    // Giao diện
    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div style={{ padding: '20px' }}>
                <form onSubmit={handleSubmit(confirmForm)}>
                    <h3>{title}</h3>
                    <Controller
                        name="id"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                sx={{ display: 'none' }}
                                fullWidth
                                label="ID"
                                variant="outlined"
                                error={!!errors['id']}
                                helperText={errors['id']?.message}
                                {...field}
                            />
                        )}
                    />

                    {formDataOrigin.map((value, index) => (
                        <Controller
                            key={index}
                            name={value.name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    sx={{ marginBottom: '20px' }}
                                    fullWidth
                                    label={value.label}
                                    type={value.type}
                                    variant="outlined"
                                    error={!!errors[value.name]}
                                    helperText={errors[value.name]?.message}
                                    {...field}
                                />
                            )}
                        />
                    ))}
                    <Button type="submit" variant="contained" fullWidth color="primary" sx={{ padding: '10px 0px' }}>
                        Xác nhận
                    </Button>
                </form>
            </div>
        </Dialog>
    );
}

export default TodoForm;
