// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogPage_page__BPeXq {\n  position: fixed;\n  top: 0px;\n  padding-top: 20px;\n  width: calc(100% - 300px);\n  background-color: #F0F2F5;\n  height: 100vh;\n  z-index: 100;\n}\n\n.DialogPage_close__BWZhp {\n  display: flex;\n  justify-content: space-between;\n}\n\n@media only screen and (max-width: 1200px) {\n  .DialogPage_page__BPeXq {\n    width: 100%;\n    height: 200vh;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    padding: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/page/compoents/DialogPage2/DialogPage.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,iBAAA;EACA,yBAAA;EACA,yBAAA;EACA,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI;IACI,WAAA;IACA,aAAA;IACA,OAAA;IACA,QAAA;IACA,SAAA;IACA,aAAA;EACN;AACF","sourcesContent":[".page {\n    position: fixed;\n    top: 0px;\n    padding-top: 20px;\n    width: calc(100% - 300px);\n    background-color: #F0F2F5;\n    height: 100vh;\n    z-index: 100;\n}\n\n.close {\n    display: flex;\n    justify-content: space-between;\n}\n\n@media only screen and (max-width: 1200px) {\n    .page {\n        width: 100%;\n        height: 200vh;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        padding: 20px;\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "DialogPage_page__BPeXq",
	"close": "DialogPage_close__BWZhp"
};
export default ___CSS_LOADER_EXPORT___;
