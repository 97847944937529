import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "9c0b3baf-e0b9-40f9-a72b-ec296e38579a", // Thay thế bằng Client ID của ứng dụng Azure AD
    authority: 'https://login.microsoftonline.com/62ac0ce1-ce41-459b-828c-34ad48cf9718', // Thay YOUR_TENANT_ID bằng Tenant ID của Azure AD
    redirectUri: "https://booking.t-matsuoka.com/checkwin", // Thay thế bằng URI đăng ký
  },
  // cache: {
  //   cacheLocation: 'sessionStorage', // Lưu trữ token trong sessionStorage
  //   storeAuthStateInCookie: false,
  // },
};

export const msalInstance = new PublicClientApplication(msalConfig);
