import React, { useEffect, useState } from 'react';
import { msalInstance } from "./authConfig"; 

function RegisterWin() {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    msalInstance.handleRedirectPromise().then((response) => {
      if (response !== null && response.account !== null) {
        setAccount(response.account);
      }
    });
  }, []);

  useEffect(() => {
    console.log(account);
    console.log("Account:");
  }, [account]);

  const handleLogin = async () => {
    const loginResponse = await msalInstance.loginRedirect();
    setAccount(loginResponse.account);
    console.log(loginResponse.account);
  };

  const handleLogout = () => {
    msalInstance.logout();
    setAccount(null);
  };

  return (
    <div>
      <h1>RegisterWin</h1>
      {account ? (
        <div>
          <p>Logged in as: {account.username}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <button onClick={handleLogin}>Login</button>
      )}
    </div>
  );
}

export default RegisterWin;
