import React, { useEffect, useState } from 'react';
import { msalInstance } from "./authConfig";

function CheckWin() {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (window.location.hash) {
      msalInstance.handleRedirectPromise().then((response) => {
        if (response !== null && response.account !== null) {
          setAccount(response.account);
        }
      });
    }
  }, []);

  // const handleLogin = async () => {
  //   const loginResponse = await msalInstance.loginRedirect(); 
  //   setAccount(loginResponse.account);
  // };

  // const handleLogout = () => {
  //   msalInstance.logout();
  //   setAccount(null);
  // };

  return (
    <div>
      {/* {account ? (
        <div>
          <p>Logged in as: {account.name}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <button onClick={handleLogin}>Login</button>
      )} */}
    </div>
  );
}


export default CheckWin;