// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CalendarData_herderCelendar__2avX- {\n  display: flex;\n  justify-content: space-around;\n}\n\n.CalendarData_week__FwNQ2 {\n  display: flex;\n  cursor: pointer;\n}\n\n.CalendarData_dayTitle__WSRMw {\n  width: 14%;\n  padding-top: 5px;\n  height: 36px;\n  display: flex;\n  justify-content: center;\n  font-size: 1.3rem;\n  margin: 0.5px;\n  background-color: #ffffff;\n  cursor: pointer;\n}\n\n.CalendarData_monthTitle__I-bjS {\n  display: flex;\n  align-items: center;\n  font-size: 1.8rem;\n  color: #25356d;\n}\n\n.CalendarData_activate__vp4rw {\n  border: 2px solid #354da3;\n  border-radius: 2px;\n}", "",{"version":3,"sources":["webpack://./src/components/CalendarData/CalendarData.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,6BAAA;AACJ;;AAKA;EACI,aAAA;EACA,eAAA;AAFJ;;AAKA;EACI,UAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EAEA,aAAA;EACA,yBAAA;EACA,eAAA;AAHJ;;AAOA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AAJJ;;AAOA;EACI,yBAAA;EACA,kBAAA;AAJJ","sourcesContent":[".herderCelendar {\n    display: flex;\n    justify-content: space-around;\n}\n\n.calendar {\n}\n\n.week {\n    display: flex;\n    cursor: pointer;\n}\n\n.dayTitle {\n    width: 14%;\n    padding-top: 5px;\n    height: 36px;\n    display: flex;\n    justify-content: center;\n    font-size: 1.3rem;\n    // border-radius: 5px;\n    margin: 0.5px;\n    background-color: #ffffff;\n    cursor: pointer;\n    // font-weight: bold;\n}\n\n.monthTitle {\n    display: flex;\n    align-items: center;\n    font-size: 1.8rem;\n    color: #25356d;\n}\n\n.activate {\n    border: 2px solid #354da3;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"herderCelendar": "CalendarData_herderCelendar__2avX-",
	"week": "CalendarData_week__FwNQ2",
	"dayTitle": "CalendarData_dayTitle__WSRMw",
	"monthTitle": "CalendarData_monthTitle__I-bjS",
	"activate": "CalendarData_activate__vp4rw"
};
export default ___CSS_LOADER_EXPORT___;
