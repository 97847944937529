// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchData_search__3mgam {\n  width: 90%;\n  background-color: #ffffff;\n}\n\n@media only screen and (max-width: 600px) {\n  .searchData_search__3mgam {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/page/compoents/SearchData/searchData.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,yBAAA;AACJ;;AAEA;EACI;IACI,WAAA;EACN;AACF","sourcesContent":[".search {\n    width: 90%;\n    background-color: #ffffff;\n}\n\n@media only screen and (max-width: 600px) {\n    .search {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "searchData_search__3mgam"
};
export default ___CSS_LOADER_EXPORT___;
