import axiosClinet from '~/api/axiosClient';

const doctorApi = {
    getAll(params) {
        const url = '/doctor/getdoctor';
        return axiosClinet.get(url, { params });
    },
    getDoctorID(id) {
        console.log(id);
        const url = '/doctor/getdoctorid';
        return axiosClinet.get(url, {
            params: {
                id,
            },
        });
    },
    add(data) {
        console.log(data);
        const url = '/doctor/adddoctor';
        return axiosClinet.post(url, data);
    },
    update(data) {
        const url = '/Doctor/updateDoctor';
        return axiosClinet.put(url, data);
    },
    updateDoctorItem(data) {
        console.log(data);
        const url = '/Doctor/updateDoctoritem';
        return axiosClinet.put(url, data);
    },
    remove(id) {
        console.log(id);
        const url = '/Doctor/removeDoctor';
        return axiosClinet.delete(url, { data: { id } });
    },
    removeDoctorItem(id) {
        console.log(id);
        const url = '/Doctor/removeDoctoritem';
        return axiosClinet.delete(url, { data: { id } });
    },
};

export default doctorApi;
