import axiosClinet from '~/api/axiosClient';

const auth2Api = {
    getData(params) {
        const url = '/auth2/getdata';
        return axiosClinet.get(url, { params });
    },
    addAcount(data) {
        console.log(data);
        const url = '/auth/register';
        return axiosClinet.post(url, { data });
    },
    editAcount(data) {
        const url = '/auth2/editAcount';
        return axiosClinet.put(url, data);
    },
    deleteAcount(userName) {
        const url = '/auth2/deleteAcount';
        return axiosClinet.delete(url, { data: { userName } });
    },
};

export default auth2Api;
