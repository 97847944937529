import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import style from './TodoTable.module.scss';

// columnsAdd Trường dữ liệu cột
// rows: trường dữ liệu
const MyTable = ({ columns, rows }) => {
    console.log('check Loop table');
    const [selectedRow, setSelectedRow] = useState(null);
    return (
        <div>
            <div className={style.todoTable}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    // pageSize={5}
                    onRowClick={(params) => setSelectedRow(params.row)}
                />
            </div>
        </div>
    );
};

export default MyTable;
