import React from 'react';
import { useEffect, useState } from 'react';
import TodoTable from '~/page/compoents/TodoTable/TodoTable.js';
import bookingApi from '~/api/booking/bookingApi';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import style from './ServiceInfo.module.scss';
import Close from '@mui/icons-material/Close';

import { TextField, Button, Dialog } from '@mui/material';
import { Diversity1Rounded } from '@mui/icons-material';

function ServiceInfo({ show, setShow, dateSelect, dataService, handleRegister }) {
    console.log('check loop service info');
    const [dataTable, setDataTable] = useState([]);
    const getDataTable = async () => {
        const idMap = {};
        var filteredArray = [];
        for (const item of dataService) {
            if (!idMap[item.id]) {
                idMap[item.id] = true;
                filteredArray.push(item);
            }
        }
        dataService = filteredArray;
        // console.log(filteredArray);

        const dataTableNew = await bookingApi.postServiceInfo({ dateSelect, dataService });
        console.log(dataTableNew);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, serviceInfo: value.id, id: index + 1 }));
        console.log(dataStt);
        setDataTable(dataStt);
    };

    useEffect(() => {
        if (show == true) {
            getDataTable();
        }
    }, [show]);

    // table
    const columns = [
        { field: 'id', headerName: 'STT', width: 30, flex: 0.5 },
        {
            field: 'serviceInfo',
            headerName: 'Dịch vụ',
            minWidth: 700,
            flex: 6,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        color: params.row.color,
                    }}
                    check={params.id}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: 'doctorID',
            headerName: 'Dự kiến',
            minWidth: 250,
            flex: 1,
            renderCell: (params) => (
                <div style={{ color: params.row.color }}>
                    {' '}
                    {params.value === 'null' ? 'Không bác sĩ' : params.value}
                </div>
            ),
        },
        {
            field: 'serviceTime',
            headerName: 'Thời gian',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <div style={{ color: params.row.color }} check={params.row.color}>
                    {params.row.doctorID === 'null' ? '0/0' : params.value}
                </div>
            ),
        },
    ];
    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };
    return (
        show && (
            <div className={style.page} open={show} onClose={handleClose}>
                <div style={{ padding: '20px' }}>
                    <div className={style.titleR}>
                        <h3>Thông tin ngày khám:</h3>
                        <div>
                            <Button
                                onClick={handleRegister}
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: '20px' }}
                                startIcon={<AppRegistrationIcon />}
                            >
                                {' '}
                                Đăng kí
                            </Button>

                            <Button aria-label="Close" variant="contained" onClick={handleClose} startIcon={<Close />}>
                                Close
                            </Button>
                        </div>
                    </div>

                    <TodoTable rows={dataTable} columns={columns} />
                </div>
            </div>
        )
    );
}

export default ServiceInfo;
