import React from 'react';
import { Button, Dialog } from '@mui/material';
// import style from './AddServiceCustomer.module.scss';
import SearchData2 from '../SearchData2/SearchData2';
import { useEffect, useState } from 'react';
import bookingApi from '~/api/booking/bookingApi';
import style from './AddServiceCustomer.module.scss';

function AddServiceCustomer({ confirmForm, show, setShow }) {
    const [data, setData] = useState([]);
    const [service, setService] = useState();
    // Lấy ra dữ liệu các dịch vụ
    useEffect(() => {
        const getData = async () => {
            var data = await bookingApi.getData();
            var dataNew = [];
            data.forEach((value) => {
                if (value.type === 'Service') {
                    dataNew.push(value);
                }
            });
            console.log(dataNew);
            setData(dataNew);
        };
        getData();
    }, []);
    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };

    const addData = async (data) => {
        if (data) {
            setService(data);
        }
    };

    const handleSubmit = () => {
        confirmForm(service);
    };

    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div className={style.search}>
                <SearchData2 data={data} addData={addData} />

                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={{ padding: '10px 0px', marginTop: '20px' }}
                    onClick={handleSubmit}
                >
                    Xác nhận
                </Button>
            </div>
        </Dialog>
    );
}

export default AddServiceCustomer;
