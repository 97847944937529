import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
// Giao diện
import { Button } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
// import file
import serviceApi from '~/api/service/serviceApi.js';
import TodoForm from '../compoents/TodoForm/TodoForm.js';
import ImportExcel from '../compoents/ImportExcel/ImportExcel.js';
import TodoTable from '../compoents/TodoTable/TodoTable.js';
import Search from '../compoents/Search/Search.js';
import ExportExcel from '../compoents/ExpotExcel/ExportExcel.js';
// Origin
// import data excel
const dataTitleExcel = ['serviceID', 'serviceName', 'serviceGroup', 'serviceTime'];
//  input form
const formDataOrigin = [
    { name: 'serviceID', label: 'Mã dịch vụ: ', type: 'text' },
    { name: 'serviceName', label: 'Tên dịch vụ:', type: 'text' },
    { name: 'serviceGroup', label: 'Nhóm: ', type: 'text' },
    { name: 'serviceTime', label: 'Thời gian: ', type: 'number' },
];
// Điều kiện form
const condition = yup.object().shape({
    serviceID: yup.string().required('Vui lòng nhập dữ liệu!'),
    serviceName: yup.string().required('Vui lòng nhập dữ liệu!'),
    serviceGroup: yup.string().required('Vui lòng nhập dữ liệu!'),
    serviceTime: yup.number().typeError('Chỉ nhập số').required('Vui lòng nhập dữ liệu!'),
});

function Service() {
    console.log('check Loop');
    //data
    const [dataTable, setDataTable] = useState([]);
    //form
    const [showForm, setShowForm] = useState(false);
    const [defaultValues, setDefaultValues] = useState();
    const [titleForm, setTitleForm] = useState('Add New');
    const [confirmForm, setConfirmForm] = useState({ confirm: serviceApi.add });
    //search
    const [dataSearch, setDataSearch] = useState();

    // call data
    const getDataTable = async () => {
        const dataTableNew = await serviceApi.getAll();
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1 }));
        setDataTable(dataStt);
    };

    useEffect(() => {
        getDataTable();
    }, []);

    //table 1:
    const columns = [
        { field: 'stt', headerName: 'STT', flex: 0.5 },
        { field: 'serviceID', headerName: 'serviceID', flex: 1 },
        { field: 'serviceName', headerName: 'serviceName', flex: 3 },
        { field: 'serviceGroup', headerName: 'serviceGroup', flex: 1 },
        { field: 'serviceTime', headerName: 'serviceTime', type: 'number', flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <EditIcon
                        onClick={() => updateSubmit(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removeTable(params.row)} style={{ cursor: 'pointer' }} />
                </>
            ),
        },
    ];

    const updateSubmit = (data) => {
        setTitleForm('Update New');
        setDefaultValues(data);
        setConfirmForm({ confirm: updateTable });
        setShowForm(true);
    };

    const updateTable = async (data) => {
        console.log('check update');
        const result = await serviceApi.update(data);
        if (result.result) {
            setShowForm(false);
            Swal.fire('Update!', 'Update success !', 'success');
            getDataTable();
        } else {
            Swal.fire({
                icon: 'Error',
                title: 'Error',
                text: result.message,
            });
        }
    };

    const removeTable = async (data) => {
        const { isConfirmed } = await Swal.fire({
            title: 'Delete ?',
            text: data.serviceID,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await serviceApi.remove(data.id);
            if (resultRemove) {
                Swal.fire('Deleted!', data.serviceID, 'success');
                getDataTable();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: 'Something went wrong!',
                });
            }
        }
    };

    // add new
    const addNewSubmit = () => {
        setTitleForm('Add New');
        setDefaultValues({});
        setConfirmForm({ confirm: addNew });
        setShowForm(true);
    };

    const addNew = async (data) => {
        console.log(data);
        const result = await serviceApi.add(data);
        console.log(result);
        if (result.result) {
            setShowForm(false);
            Swal.fire('Add New!', 'Add New success !', 'success');
            getDataTable();
        } else {
            // setShowForm(false);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: result.message,
            });
        }
    };

    // search
    const handleSearch = (e) => {
        e.preventDefault();
        console.log(dataSearch);
    };

    return (
        <div>
            <div className="between">
                <div>
                    <form onSubmit={handleSearch}>
                        <Search setDataSearch={setDataSearch} />
                    </form>
                </div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Button
                            sx={{ marginRight: '10px' }}
                            onClick={addNewSubmit}
                            variant="contained"
                            component="label"
                            startIcon={<AddBox />}
                        >
                            Add New
                        </Button>
                    </div>

                    <div style={{ marginRight: '10px' }}>
                        <ImportExcel add={serviceApi.add} dataTitleExcel={dataTitleExcel} loadData={getDataTable} />
                    </div>
                    <div>
                        <ExportExcel data={dataTable} fileName="serviceBook"></ExportExcel>
                    </div>
                </div>
            </div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultValues}
                        formDataOrigin={formDataOrigin}
                        title={titleForm}
                        condition={condition}
                        setDataTable={setDataTable}
                    />
                )}
            </div>
            <div>
                <TodoTable rows={dataTable} columns={columns} />
            </div>
        </div>
    );
}

export default Service;
