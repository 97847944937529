// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_body__eaLDJ {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 100vh;\n}\n\n.Login_formLogin__35uVf {\n  width: 500px;\n  padding: 50px;\n}", "",{"version":3,"sources":["webpack://./src/Ath/login/Login.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ","sourcesContent":[".body {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.formLogin {\n    width: 500px;\n    padding: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "Login_body__eaLDJ",
	"formLogin": "Login_formLogin__35uVf"
};
export default ___CSS_LOADER_EXPORT___;
