import { Header } from '~/Layouts/components/Header';

function PrivateLayout({ children }) {
    return (
        <div>
            <Header />
            <h1>Test</h1>
            <div className="Content">{children}</div>
        </div>
    );
}

export default PrivateLayout;
