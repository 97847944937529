import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
    typography: {
        fontFamily: 'Bai Jamjuree', // thay đổi font-family mặc định
        fontSize: 20, // thay đổi font-size mặc định
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 10,
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff',
                    borderRadius: 10,
                    marginTop: 10,
                },
                cell: {
                    maxHeight: 'auto !important',
                },
            },
        },
    },
});

function ThemeMui(props) {
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default ThemeMui;
