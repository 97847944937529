import { Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '~/Layouts/components/Header';
import { Sidebar } from '~/Layouts/components/Sidebar';
import styles from '~/Layouts/DefaultLayout/DefaultLayout.module.scss';
import { checkLoginSessions } from '~/api/auth/auth.js';

function DefaultLayout({ children }) {
    const navigate = useNavigate();
    const isToken = localStorage.getItem('token');

    // Check token
    useEffect(() => {
        const checkToken = async () => {
            const check = await checkLoginSessions();
            if (!check) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                // console.log('check session');
            }
        };
        checkToken();
    });

    return (
        <div>
            {!isToken ? (
                <Navigate to="/login" />
            ) : (
                <div>
                    <Header />
                    <Sidebar />
                    <div className={styles.content}>
                        <div className={styles.content_info}>{children}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DefaultLayout;
