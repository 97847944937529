import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "1ec68b2c-c473-4aac-9d28-ae9b9c3c7ebb", // Thay thế bằng Client ID của ứng dụng Azure AD
    // authority: 'https://login.microsoftonline.com/62ac0ce1-ce41-459b-828c-34ad48cf9718', // Thay YOUR_TENANT_ID bằng Tenant ID của Azure AD
    redirectUri: "/", // Thay thế bằng URI đăng ký

  },
  // cache: {
  //   cacheLocation: 'sessionStorage', // Lưu trữ token trong sessionStorage
  //   storeAuthStateInCookie: false,
  // },
};

export const msalInstance = new PublicClientApplication(msalConfig);
