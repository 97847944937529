import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import style from './searchData.module.scss';

export default function SearchData({ data, addData }) {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event, newValue) => {
        setSelectedOption({ label: '' });
        addData(newValue);
    };

    return (
        <Autocomplete
            options={data}
            getOptionLabel={(option) => option.label}
            onChange={handleOptionChange}
            value={selectedOption}
            className={style.search}
            renderInput={(params) => <TextField {...params} label="Tìm kiếm..." variant="outlined" />}
        />
    );
}
